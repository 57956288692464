h5{
    text-align: center;
    margin-top: 20px;
}
.content{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 5vh;
}


@media (max-width: 768px) {
    .projectfield{
        margin-top: 10px;
    }
}

@media (max-width: 480px) {
    .projectfield{
        margin-top: 10px;
    }
}