h1{
    text-align: center;
    margin: 20px auto 2px auto;
    text-decoration: underline;
    font-size: 5vh;
}

.content{
    padding: 40px;
    margin: 30px;
}

.myName{
    display: flex;
    gap: 10px;
}

.basicInfo{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
h4{
    text-align: center;
    border: 2px solid;
    width: 115px;
    height: 33px;
    border-radius: 35%;
    overflow: hidden;
    margin: 5px auto;
}
.primary{
    height: 10px;
    width: 15px;
    background-color: rgb(69, 68, 67);
    overflow: hidden;
    color: rgb(255, 255, 255);
}
.secondary{
    height: 10px;
    width: 15px;
    background-color: rgb(215, 56, 56);
    overflow: hidden;
    color: rgb(255, 255, 255);
}
.languages{
    display: flex;
    gap: 2px;
}

.aboutMe{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    border-top: 2px dotted;
    border-left: 2px dotted;
    font-size: 14px;
}

.foot{
    margin-top: 10px;
}

.main {
    width: 50px;
 height: 50px;
 margin: 20px auto;
 background: #fcfcfc;
 border-radius: 50%;
 position: relative;
 box-shadow:
   0 0 4px 1px rgba(0,0,0,0.2),
   0 3px 2px rgba(0,0,0,0.2);
 
 line-height: 45px;
 font-weight: bold;
 color: #505b65;
 text-align: center;
 font-size: 20px;
 font-family: 'Cutive', serif;
}

.main span {
    color: #f15755; 
}

.main .role {
    text-transform: uppercase;
 position: absolute;
 font-family: 'Advent Pro', sans-serif;
 font-size: 12px;
 color: #a2a7ac;
 left: 29px;
 bottom: 1px;
 text-align: left;
}

.main:after {
    content: "";
 position: absolute;
 border-radius: inherit;
 z-index: -1;
 background: #c8c8c8;
 background: linear-gradient(#c8c8c8, #dcdcdc);
 left: -15px; top: -15px; right: -15px; bottom: -15px;
 box-shadow:
   inset 0 1px 0 rgba(0,0,0,0.2),
   0 1px 0 rgba(255,255,255,0.9);
}

.main:before {
    content: "";
 position: absolute;
 height: 3px;
 width: 30px;
 border-radius: 40px;
 left: 47%;
 bottom: 40px;
}