h2{
    text-align: center;
} 

.blogs{
    margin: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.blog1, .blog2, .blog3{
    padding: 20px;
    width: 80%;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    display: flex;
    flex-direction: column;

}