body, html {
    height: 100%;
    margin: 0;
}
.creds{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: rgb(179, 183, 183);
    margin-top: auto;
    /* bottom: 0; */
}
.copyright{
    text-align: center;
    font-size: medium;
    
}

@media (max-width: 768px) {
    .contactIcons{
        margin-right: 5px;
    }
}

@media (max-width: 480px) {
    .contactIcons{
        margin-right: 5px;
    }
}