.blog-head{
    text-decoration: underline;
}

.blog-date{
    color: rgb(105, 96, 96);
}

.blog-body{
    text-align: left;
    padding: 70px;
    font-family: "Anek Devanagari", sans-serif;
    font-size: 20px;
}